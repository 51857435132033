<script setup lang="ts">
import { computed, type PropType, type Ref, ref, watch } from 'vue';
import { useMetaStore } from '~/stores/meta.store';
import { useRouter, useRoute } from 'vue-router';
import { type Project } from '~/types/project.type';
import { type Pagination } from '~/types/pagination.type';
import { type Country } from '~/types/country.type';
import { type MetaAttributes } from '~/types/meta-attributes.type';
import ProjectCard from './partials/project-card.vue';
import DesktopProjectsFilter from './partials/filters.vue';
import ContactModal from './partials/contact-modal.vue';
import MenuHeader from './partials/header-menu.vue';
import { useProjectsFiltersStore } from '~/stores/projects-filters.store';
import { useProjectsStore } from '~/stores/projects.store';

const props = defineProps<{
  data: {
    projects: Project[];
    country: Country;
    pagination: Pagination;
	meta: MetaAttributes
},
}>();

const router = useRouter();
const route = useRoute();
const metaStore = useMetaStore();
const filtersStore = useProjectsFiltersStore();
const projectsStore = useProjectsStore();

const location = route.params.location;

const isEmailFormOpen = ref(false);
const page: Ref<Number> = ref(Number(props.data?.data?.pagination?.currentPage) || 1);
const pageData: Ref<{
	projects: Project[];
	country: Country;
	pagination: Pagination
}> = computed(() => props.data.data);

const country_id = pageData.value.country.country_id || pageData.value.country._id;

onUpdated(() => {
	window?.scrollTo({ top: 0, behavior: 'smooth' });
});

watch(page, async (newPage) => {
	// Update query
	await router.push({
		query: { page: newPage.toString() },
	});
});

watch(() => route.query, async () => {
	// No need to make request if location changed as NuxtLink automatically makes the request
	if (filtersStore.originalLocation === location) {
		await projectsStore.fetchProjects(route);
	}
});

const showEmailForm = () => {
	isEmailFormOpen.value = true;
}
metaStore.setMeta(props.data.meta);
</script>

<template>
	<LayoutAppHead></LayoutAppHead>

	<NuxtLayout name="desktop-projects">
		<template #menus>
			<menu-header :country_id="data.data.country.country_id || data.data.country._id"></menu-header>
		</template>

		<main>
			<div class="relative mx-auto mb-2 z-0">
				<img class="w-full h-60 object-cover" :src="`${pageData.country.background}?w=1728&h=240&fit=cover`" :alt="pageData.country.name">
				<div class="absolute inset-0 bg-indigo-950 opacity-80 rounded-md"></div>
				<div class="absolute bottom-1/4 p-5 left-0 flex flex-col w-full">
					<h2 class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 text-white text-4xl font-medium tracking-wide mb-5">
						Projects
					</h2>
				</div>
			</div>
			<section class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">

				<div class="grid grid-cols-4 gap-4">
					<div class="mb-8 relative">
						<DesktopProjectsFilter :country_id="country_id"></DesktopProjectsFilter>
					</div>
					<div class="mb-8 col-span-3">
						<template v-if="pageData.projects && pageData.projects?.length">
							<div class="grid grid-cols-2 gap-4">
								<div v-for="project in pageData.projects" :key="project._id">
									<ProjectCard :project="project" :country="pageData.country"
										:showEmailForm="showEmailForm" />
								</div>
							</div>
							<div class="flex justify-center mt-4">
								<w-pagination v-model="page" :page-count="pageData.pagination?.perPage"
									:total="pageData.pagination?.total" size="md" />
							</div>
						</template>
						<div v-else class="text-center text-2xl py-12">
							{{ $t('client.no_projects') }}
						</div>
					</div>
				</div>
			</section>

			<ContactModal v-model="isEmailFormOpen" />
		</main>
	</NuxtLayout>
</template>
